export const environment = {
  production: false,
  jobPrefix: 'ER',
  clientID: 5159,
  logoConfig: {
    showLogoInToolbar: true,
    logoIsSquare: false,
  },
  usesPriorityNotDateTime: true,
  useConvertToTitleCase: false,
  loginConfig: {
    isPasswordProtected: true,
    authenticatesViaWebservice: true,
  },
  enforcedImageAttachment: true,
  sendsSupervisorEmailForEmergencyJobs: true,
  webserviceURL: "https://easylinkapi.johnslyng.com.au/",
  videoServiceURL: 'https://easylinkvideoserviceapi.johnslyng.com.au/',
  loadsStoreDataFromFirebase: true,
  mapsConfig: {
    usesMapsAPI: false,
    googleMapsCountries: [],
    isBoundToRegion: true
  },
  activeDDLOptions: {
    country: false,
    region: true,
    jobSite: true,
    serviceType: true,
    clubType: true
  },
  contactOptions: {
    secondEmail: false,
  },
  servicePortal: {
    hasServicePortal: false,
    servicePortalURL: ""
  },
  // webserviceURL: "http://localhost:51843/",
  firebase: {
    projectId: "viva-live-a0804",
    appId: "1:476791570660:web:ea0122d74a1713f3722cf1",
    storageBucket: "viva-live-a0804.appspot.com",
    apiKey: "AIzaSyBaPqnjExKJ9nkWxJTHU-yobYbCUHUiqP4",
    authDomain: "viva-live-a0804.firebaseapp.com",
    messagingSenderId: "476791570660"
  },
  usesBusinessName: false,
  showInsertDummyJobButton: false
};